import { ProductVariationWithSample } from "@/stores/cart";
import { SampleRequestKind } from "@/web-client/api";
import { useMemo } from "react";
import { isSampleAvailable as checkSampleAvailable } from "@/utils/isSampleAvailable";

type UseProductVariationReturn = {
  isSampleAvailable: boolean;
  isOutOfStock: boolean;
  isIndirectAvailable: boolean;
};
const useProductVariation = (
  productVariation: ProductVariationWithSample,
): UseProductVariationReturn => {
  // サンプル請求が可能かどうか（在庫は問わない）
  const isSampleAvailable = useMemo(
    () => checkSampleAvailable(productVariation),
    [productVariation],
  );

  // TSSが利用可能かどうか（在庫は問わない)
  const isIndirectAvailable = useMemo<boolean>(
    () =>
      productVariation.sample_request_kind === SampleRequestKind.INDIRECT &&
      productVariation.sample_item !== undefined,
    [productVariation],
  );

  // 在庫切れ
  const isOutOfStock = useMemo(() => {
    switch (productVariation.sample_request_kind) {
      // 在庫切れの概念がない
      case SampleRequestKind.DIRECT:
      case SampleRequestKind.UNABLE:
        return false;

      case SampleRequestKind.INDIRECT:
        if (productVariation.sample_item) {
          return productVariation.sample_item.stock_count < 1;
        }

        // サンプルが紐づいていないので在庫切れとはみなさない
        return false;
    }
  }, [productVariation]);

  return {
    isSampleAvailable,
    isOutOfStock,
    isIndirectAvailable,
  };
};
export default useProductVariation;
