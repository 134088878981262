import { ProductVariationWithSample } from "@/stores/cart";
import { SampleRequestKind } from "@/web-client/api";

export const isSampleAvailable = (
  productVariation: ProductVariationWithSample,
): boolean => {
  switch (productVariation.sample_request_kind) {
    case SampleRequestKind.UNABLE:
      return false;

    case SampleRequestKind.DIRECT:
      return true;

    case SampleRequestKind.INDIRECT:
      // サンプル商品が紐づいていれば利用可能
      return productVariation.sample_item !== undefined;
  }
};
