import { MakerContactKind } from "@/web-client/api";
import { ReactSelectOptionValue } from "@/types/ReactSelectOptionValue";

const ContactKinds: ReactSelectOptionValue<number>[] = [
  { label: "在庫・納期について", value: MakerContactKind.STOCK },
  { label: "製品について", value: MakerContactKind.PRODUCT },
  { label: "特注について", value: MakerContactKind.CUSTOM },
  { label: "お見積りについて", value: MakerContactKind.QUOTE },
  { label: "その他", value: MakerContactKind.OTHER },
];
export default ContactKinds;
