import { FC } from "react";
import { ProductVariationWithSample } from "@/stores/cart";
import ProductVariationBasicInfoBox from "@/features/product_variation/components/ProductVariationBasicInfoBox";

type Props = {
  productVariation: ProductVariationWithSample;
  onSelected: VoidFunction;
};

const ProductVariationSelectableItem: FC<Props> = ({
  productVariation,
  onSelected,
}): JSX.Element => {
  return (
    <article className="flex items-start justify-between gap-24 py-24 laptop:px-16">
      <ProductVariationBasicInfoBox productVariation={productVariation} />

      <div className="flex flex-col items-end gap-8">
        <button className="bg-yellow-500 rounded-sm p-8" onClick={onSelected}>
          選択する
        </button>
      </div>
    </article>
  );
};
export default ProductVariationSelectableItem;
