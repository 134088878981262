import {
  ProductDetailResponse,
  ProductVariationResponse,
} from "@/web-client/api";
import { FC } from "react";
import ProductVariationSelectableItem from "@/features/product_variation/components/ProductVariationSelectableItem";
import BorderList from "@/components/BorderList";

type MakerContactModalSelectVariationContentProps = {
  product?: ProductDetailResponse;
  onSelected: (productVariation: ProductVariationResponse) => void;
  back: VoidFunction;
};

const MakerContactModalSelectVariationContent: FC<
  MakerContactModalSelectVariationContentProps
> = ({ product, back, onSelected }) => {
  return (
    <div className="space-y-24">
      <header className="flex flex-col items-center gap-12">
        <h2 className="font-bold text-xl laptop:text-3xl text-center">
          バリエーションの選択
        </h2>
        <p className="text-sm text-center">
          製品に関する具体的なお問い合わせをご希望の場合は、以下から選択してください。
        </p>
      </header>

      <BorderList borderStyle="solid">
        {product.variations.map((productVariation) => (
          <li key={productVariation.id}>
            <ProductVariationSelectableItem
              productVariation={productVariation}
              onSelected={() => onSelected(productVariation)}
            />
          </li>
        ))}
      </BorderList>

      <div className="sticky bottom-0 flex items-center justify-center py-8 bg-white">
        <button className="font-bold underline p-8" onClick={back}>
          入力画面へ戻る
        </button>
      </div>
    </div>
  );
};
export default MakerContactModalSelectVariationContent;
