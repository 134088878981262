import { FC, PropsWithChildren, useMemo } from "react";

interface Props {
  rowGap?: number;
  columnGap?: number;
  minWidth?: number;
  maxWidth?: number;
}

const FlexibleGrid: FC<PropsWithChildren<Props>> = ({
  children,
  rowGap,
  columnGap,
  minWidth,
  maxWidth,
}): JSX.Element => {
  const itemMaxWidth = useMemo<string>(() => {
    return maxWidth ? `${maxWidth}px` : "1fr";
  }, [maxWidth]);

  return (
    <ul
      className="grid"
      style={{
        gridTemplateColumns: `repeat(auto-fill, minmax(${minWidth}px, ${itemMaxWidth}))`,
        columnGap: `${columnGap}px`,
        rowGap: `${rowGap}px`,
      }}
    >
      {children}
    </ul>
  );
};
FlexibleGrid.defaultProps = {
  rowGap: 24,
  columnGap: 24,
  minWidth: 320,
};
export default FlexibleGrid;
