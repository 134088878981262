import { LikeKind, ProductKind } from "@/web-client/api";
import useLike, { LikeableEntity, UseLikeReturn } from "./useLike";

export type LikeMutateHandler<T> = (updatedData: T) => void;

const useLikeToProduct = <T extends LikeableEntity>(
  target: T,
  kind: ProductKind,
  onUpdated: LikeMutateHandler<T>,
  makerId?: number,
): UseLikeReturn => {
  const { likeHandler, status } = useLike<T>(
    target,
    kind === ProductKind.BUILDING_MATERIAL
      ? LikeKind.BUILDING_MATERIAL
      : LikeKind.INTERIOR,
    onUpdated,
    makerId,
  );

  return {
    likeHandler,
    status,
  };
};
export default useLikeToProduct;
