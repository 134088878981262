import formatNumber from "@/utils/formatNumber";
import { CSSProperties, useMemo } from "react";

const useDisplayPrice = (
  values: {
    min_price?: number;
    max_price?: number;
    unit?: string;
  },
  styles: {
    wrapperStyle?: CSSProperties;
    wrapperClassName?: string;

    priceWrapperStyle?: CSSProperties;
    priceWrapperClassName?: string;

    prefixStyle?: CSSProperties;
    prefixClassName?: string;

    suffixStyle?: CSSProperties;
    suffixClassName?: string;

    unitStyle?: CSSProperties;
    unitClassName?: string;
  } = {},
): JSX.Element => {
  const priceHtml = useMemo(() => {
    if (!values.min_price) return null;

    if (values.min_price === values.max_price) {
      return <span>{formatNumber(values.min_price)}</span>;
    }

    return (
      <>
        <span>{formatNumber(values.min_price)}</span>
        <div>
          <span> 〜 </span>
          {values.max_price > values.min_price && (
            <span>{formatNumber(values.max_price)}</span>
          )}
        </div>
      </>
    );
  }, [values.min_price, values.max_price]);

  // values.min_priceが0もしくはundefinedの時点で表記しない(管理画面状に表記しているルール）
  if (priceHtml == null) return null;

  return (
    <div
      className={[
        "flex gap-4 leading-none items-end",
        styles.wrapperClassName || "",
      ].join(" ")}
      style={styles.wrapperStyle}
    >
      <div className={styles.prefixClassName} style={styles.prefixStyle}>
        &yen;
      </div>
      <div
        className={["flex gap-4"].join(" ")}
        style={styles.priceWrapperStyle}
      >
        {priceHtml}
      </div>
      {values.unit && (
        <div className={styles.unitClassName} style={styles.unitStyle}>
          /&nbsp;{values.unit}
        </div>
      )}
      <div className={styles.suffixClassName} style={styles.suffixStyle}>
        ［税抜］
      </div>
    </div>
  );
};
export default useDisplayPrice;
