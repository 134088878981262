import {
  MakerContactResponse,
  MakerResponse,
  ProductDetailResponse,
  ProductVariationResponse,
} from "@/web-client/api";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import Modal from "@/components/Modal";
import useDevice from "@/hooks/useDevice";
import MakerContactModalFormContent from "@/features/maker_contact/components/MakerContactModal/components/MakerContactModalFormContent";
import MakerContactModalCompleteContent from "@/features/maker_contact/components/MakerContactModal/components/MakerContactModalCompleteContent";
import MakerContactModalSelectVariationContent from "@/features/maker_contact/components/MakerContactModal/components/MakerContactModalSelectVariationContent";

type Props = {
  isOpen: boolean;
  closeHandler: () => void;
  maker: MakerResponse;
  product?: ProductDetailResponse;
};

enum MakerContactModalContentKind {
  Input = 1,
  Select,
  Complete,
}

const MakerContactModal: FC<Props> = ({
  isOpen,
  closeHandler,
  maker,
  product,
}): JSX.Element => {
  const { isPc } = useDevice();
  const scrollContainer = useRef<HTMLDivElement>();

  const [content, setContent] = useState<MakerContactModalContentKind>(
    MakerContactModalContentKind.Input,
  );
  const [contact, setContact] = useState<MakerContactResponse | null>(null);
  const [selectedProductVariation, setSelectedProductVariation] =
    useState<ProductVariationResponse | null>(null);

  const startSelectProductVaratinHandler = useCallback(
    () => setContent(MakerContactModalContentKind.Select),
    [],
  );

  const deselectProductVariationHandler = useCallback(
    () => setSelectedProductVariation(null),
    [],
  );

  const submittedHandler = useCallback((contact: MakerContactResponse) => {
    setContact(contact);
    setContent(MakerContactModalContentKind.Complete);
  }, []);

  const selectedProductVariationHandler = useCallback((productVariation) => {
    setSelectedProductVariation(productVariation);
    setContent(MakerContactModalContentKind.Input);
  }, []);

  // モーダルが閉じられたら,ステートをリセット
  useEffect(() => {
    if (!isOpen) {
      if (contact) {
        // すでにお問い合わせが完了していたら,お問い合わせ内容をリセット
        setSelectedProductVariation(null);
        setContact(null);
      }

      // モーダルを閉じたら入力画面に戻す
      setContent(MakerContactModalContentKind.Input);
    }
  }, [isOpen, contact]);

  // 中のコンテンツが切り替わるたびにスクロール量を０に戻す
  useEffect(() => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollTop = 0;
    }
  }, [content]);

  // バリエーションが１つしかなければ、そのバリエーションを選択状態にする
  useEffect(() => {
    if (!product) return;

    if (product.variations.length === 1) {
      setSelectedProductVariation(product.variations[0]);
    }
  }, [product]);

  return (
    <Modal
      contentStyle={{
        minWidth: isPc ? 800 : "unset",
        maxWidth: 640,
        width: "100%",
      }}
      isOpen={isOpen}
      closeHandler={closeHandler}
      contentRef={(node) => {
        if (node) {
          scrollContainer.current = node;
        }
      }}
    >
      <div className="px-12 laptop:px-32 pb-16">
        {content === MakerContactModalContentKind.Complete && (
          <MakerContactModalCompleteContent
            contact={contact}
            close={closeHandler}
          />
        )}

        {content === MakerContactModalContentKind.Input && (
          <MakerContactModalFormContent
            onStartSelect={startSelectProductVaratinHandler}
            onSubmitted={submittedHandler}
            onDeselect={deselectProductVariationHandler}
            maker={maker}
            product={product}
            selectedProductVariation={selectedProductVariation}
          />
        )}

        {content === MakerContactModalContentKind.Select && (
          <MakerContactModalSelectVariationContent
            product={product}
            onSelected={selectedProductVariationHandler}
            back={() => setContent(MakerContactModalContentKind.Input)}
          />
        )}
      </div>
    </Modal>
  );
};
export default MakerContactModal;
