import { FC } from "react";
import { ProductVariationWithSample } from "@/stores/cart";
import useDisplayPrice from "@/hooks/useDisplayPrice";
import useVariation from "@/features/product_variation/hooks/useVariation";

type Props = {
  productVariation: ProductVariationWithSample;
};

const ProductVariationBasicInfoBox: FC<Props> = ({
  productVariation,
}): JSX.Element => {
  const displayPrice = useDisplayPrice(productVariation, {});
  const { displaySize } = useVariation(productVariation);

  return (
    <div className="flex items-start gap-16">
      <img
        className="w-[64px] h-[64px] laptop:w-[100px] laptop:h-[100px] object-contain border border-primary bg-white flex-shrink-0"
        src={productVariation.upload_image.urls.small}
        alt={productVariation.item_number}
      />
      <div className="space-y-12">
        <div>
          <p>{productVariation.full_name}</p>
          {productVariation.item_number && (
            <p className="text-xs text-secondary">
              {productVariation.item_number}
            </p>
          )}
        </div>

        <div className="text-xs">
          {displayPrice}
          {displaySize && <p>{displaySize}(mm)</p>}
        </div>
      </div>
    </div>
  );
};
export default ProductVariationBasicInfoBox;
