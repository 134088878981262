import {
  CSSProperties,
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
} from "react";
import ReactModal from "react-modal";

interface Props {
  isOpen: boolean;
  closeHandler?: () => void;
  contentStyle?: CSSProperties;
  overlayStyle?: CSSProperties;
  scrollableTargetId?: string;
  contentRef?: (node: HTMLDivElement) => void;
}

const modalStyle: ReactModal.Styles = {
  content: {},
  overlay: {
    position: "fixed",
    backgroundColor: "rgba(0,0,0,0.7)",
    zIndex: 9999,

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 12,
  },
};

const Modal: FC<PropsWithChildren<Props>> = ({
  children,
  isOpen,
  closeHandler,
  contentStyle,
  overlayStyle,
  scrollableTargetId,
  contentRef,
}): JSX.Element => {
  useEffect(() => {
    ReactModal.setAppElement("body");
  }, []);

  const baseContentStyle = useMemo<CSSProperties>(() => {
    return {
      inset: "unset",
      position: "relative",
      overflow: "scroll",
      maxHeight: "80%",
      width: "100%",
      maxWidth: 1024,
      padding: 0,
    };
  }, []);

  const style = useMemo<ReactModal.Styles>(() => {
    return {
      content: {
        ...modalStyle.content,
        ...baseContentStyle,
        ...contentStyle,
      },
      overlay: {
        ...modalStyle.overlay,
        ...overlayStyle,
      },
    };
  }, [contentStyle, overlayStyle, baseContentStyle]);

  if (!isOpen) {
    return null;
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeHandler}
      style={style}
      id={scrollableTargetId}
      ariaHideApp={false}
      contentRef={contentRef}
    >
      {closeHandler && (
        <header className="sticky top-0 flex items-center justify-end p-8 laptop:p-12 z-10">
          <button onClick={closeHandler}>
            <i className="icon-Close"></i>
          </button>
        </header>
      )}
      <div>{children}</div>
    </ReactModal>
  );
};

export default Modal;
