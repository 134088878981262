import { ProductVariationWithCatalogPagesResponse } from "@/web-client/api";
import { useMemo } from "react";

interface UseVariationResponse {
  displaySize: string;
  hasPriceInfo: boolean;
  hasSizeInfo: boolean;
  hasMaterialInfo: boolean;
  hasSample: boolean;
}

const useVariation = (
  variation: ProductVariationWithCatalogPagesResponse,
): UseVariationResponse => {
  const displaySize = useMemo(() => {
    const values = [];

    if (variation.width) values.push(variation.width);

    if (variation.height) values.push(variation.height);

    if (variation.depth) values.push(variation.depth);

    return values.join(" x ");
  }, [variation]);

  const hasPriceInfo = useMemo<boolean>(
    () => !!variation.max_price || !!variation.min_price,
    [variation],
  );

  const hasSizeInfo = useMemo<boolean>(
    () =>
      !!variation.width ||
      !!variation.height ||
      !!variation.depth ||
      !!variation.size_text,
    [variation],
  );

  const hasMaterialInfo = useMemo<boolean>(() => {
    if (!variation.materials) return false;

    return variation.materials.length > 0 || !!variation.material_text;
  }, [variation]);

  const hasSample = useMemo(() => !!variation.sample_item, [variation]);

  return {
    displaySize,
    hasSizeInfo,
    hasMaterialInfo,
    hasPriceInfo,
    hasSample,
  };
};
export default useVariation;
