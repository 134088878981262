import { FC } from "react";

const SampleItemTSSDeliveryLabel: FC = ({}) => {
  return (
    <div className="bg-indirectDeliver-normal text-white rounded-xs text-xs p-[6px] px-8 leading-none">
      最短当日発送
    </div>
  );
};
export default SampleItemTSSDeliveryLabel;
